
import Header from './header.vue'
import Footer from './footer.vue'
export default {
  name: 'GASUtopiaLayout',
  components: {
    Header,Footer
  },
  mounted() {
     
  },
  methods: {
   
  },
}
