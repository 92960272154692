
import detail from '../detail.json'
export default {
    layout: 'permanent/gasutopia/default',
    name: 'GAUStopiaDetailPage',
    scrollToTop: true,
    async asyncData(app) {
        return {
            params: app.params,
            contentId: app.params.contentId
        }
    },
    data() {
        return {
            detail: detail[this.contentId] ? detail[this.contentId] : detail["1"]
        }
    },
    head() {
        return{
            title: this.detail.title,
            meta: [
                {hid: 'keywords', name: 'keywords', content:"gasutopia, gasu, stablecoin"},
                {hid: 'description', name: 'description', content: this.detail.describe},
                {hid:'og:title',name: 'og:title', content:this.detail.title},
                {hid:'og:description', name: 'og:description', content:this.detail.describe },
                {hid:'og:url', name: 'og:url', content:"https://gasutopia.com" },
                {hid:'og:type', name: 'og:type', content:"website"},
                {hid:'og:image', name: 'og:image', content:"https://cdn.bitkeep.vip/u_b_f01ef770-1705-11ed-aa23-8f000e627696.png"},
                {hid:'og:image_src', name: 'og:image_src', content:"https://cdn.bitkeep.vip/u_b_f01ef770-1705-11ed-aa23-8f000e627696.png"},
                {hid:'og:image_alt', name: 'og:type', content:"GASU"},
                {hid:'twitter:title',name: 'twitter:title', content: this.detail.title},
                {hid:'twitter:description', name: 'twitter:description', content:this.detail.describe },
                {hid:'twitter:url', name: 'twitter:url', content:"https://gasutopia.com" },
                {hid:'twitter:image', name: 'twitter:image', content:"https://cdn.bitkeep.vip/u_b_f01ef770-1705-11ed-aa23-8f000e627696.png"},
                {hid:'twitter:image_src', name: 'twitter:image_src', content:"https://cdn.bitkeep.vip/u_b_f01ef770-1705-11ed-aa23-8f000e627696.png"},
                {hid:'twitter:image_alt', name: 'twitter:type', content:"GASU"},
                {hid:'twitter:site', name: 'twitter:site', content:"gasutopia"},
                {hid:'twitter:card', name: 'twitter:card', content:"summary_large_image"},
            ],
            link: [
                {hid:'icon', rel: 'icon', type:"image/x-icon", href: "https://cdn.bitkeep.vip/u_b_f01d22b0-1705-11ed-aa23-8f000e627696.png" },
                {hid:'shortcut icon', rel: 'shortcut icon', type:"image/x-icon", href: "https://cdn.bitkeep.vip/u_b_f01d22b0-1705-11ed-aa23-8f000e627696.png" },
            ]        
        }
    }
}
