
export default {
    layout: 'permanent/gasutopia/default',
    name: 'GAUStopiaPage',
    scrollToTop: true,
    head() {
        return{
            title: "GASUtopia($GASU) - Universal Gas infrastructure to enhance the web3 experience",
            meta: [
                {hid: 'keywords', name: 'keywords', content: "GASUtopia, GASU, stablecoin" },
                {hid: 'description', name: 'description', content: "GASUtopia makes multi-chain transactions simple. Enable a universal gas token GASU - anchoring USD, keep scalability, independent of all public chains." },
                {hid:'og:title',name: 'og:title', content:"GASUtopia($GASU) - Universal Gas infrastructure to enhance the web3 experience"},
                {hid:'og:description', name: 'og:description', content:"GASUtopia makes multi-chain transactions simple. Enable a universal gas token GASU - anchoring USD, keep scalability, independent of all public chains." },
                {hid:'og:url', name: 'og:url', content:"https://gasutopia.com" },
                {hid:'og:type', name: 'og:type', content:"website"},
                {hid:'og:image', name: 'og:image', content:"https://cdn.bitkeep.vip/u_b_f01ef770-1705-11ed-aa23-8f000e627696.png"},
                {hid:'og:image_src', name: 'og:image_src', content:"https://cdn.bitkeep.vip/u_b_f01ef770-1705-11ed-aa23-8f000e627696.png"},
                {hid:'og:image_alt', name: 'og:type', content:"GASU"},
                {hid:'twitter:title',name: 'twitter:title', content: "GASUtopia($GASU) - Universal Gas infrastructure to enhance the web3 experience"},
                {hid:'twitter:description', name: 'twitter:description', content:"GASUtopia makes multi-chain transactions simple. Enable a universal gas token GASU - anchoring USD, keep scalability, independent of all public chains." },
                {hid:'twitter:url', name: 'twitter:url', content:"https://gasutopia.com" },
                {hid:'twitter:image', name: 'twitter:image', content:"https://cdn.bitkeep.vip/u_b_f01ef770-1705-11ed-aa23-8f000e627696.png"},
                {hid:'twitter:image_src', name: 'twitter:image_src', content:"https://cdn.bitkeep.vip/u_b_f01ef770-1705-11ed-aa23-8f000e627696.png"},
                {hid:'twitter:image_alt', name: 'twitter:type', content:"GASU"},
                {hid:'twitter:site', name: 'twitter:site', content:"gasutopia"},
                {hid:'twitter:card', name: 'twitter:card', content:"summary_large_image"},
            ],
            link: [
                {hid:'icon', rel: 'icon', type:"image/x-icon", href: "https://cdn.bitkeep.vip/u_b_f01d22b0-1705-11ed-aa23-8f000e627696.png" },
                {hid:'shortcut icon', rel: 'shortcut icon', type:"image/x-icon", href: "https://cdn.bitkeep.vip/u_b_f01d22b0-1705-11ed-aa23-8f000e627696.png" },
            ]       
        }
    },
    methods: {
        knowMore(item, event) {
            if (event && event.preventDefault) {
                event.preventDefault();
            } else {
                window.event.returnValue = false; //兼容IE8
            }
            this.$router.push({
                path: `/news/${item}`
            })
        }
    },
}
