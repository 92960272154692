
export default {
    name: "GASUtopiaHeader",
    data() {

        return {

        }
    },
    head() {

    },
    beforeMount() {

    },
    methods: {

    }
}
